import { render, staticRenderFns } from "./PostPreview.vue?vue&type=template&id=1d7f02bd&scoped=true&"
import script from "./PostPreview.vue?vue&type=script&lang=ts&"
export * from "./PostPreview.vue?vue&type=script&lang=ts&"
import style0 from "./PostPreview.vue?vue&type=style&index=0&id=1d7f02bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d7f02bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KImage: require('/tmp/build_acca07ff/apps/web/components/image/KImage.vue').default,PostVisivilityLevel: require('/tmp/build_acca07ff/apps/web/components/post/PostVisivilityLevel.vue').default,PostCounterDetails: require('/tmp/build_acca07ff/apps/web/components/post/PostCounterDetails.vue').default})
